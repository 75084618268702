<template>
  <div class="card p-shadow-6">
    <h1>Categoria dos Manuais</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog></ConfirmDialog>
    <DataTable
      :value="listCategories"
      :filters.sync="filters"
      filterDisplay="row"
      :loading="loading"
      :paginator="true"
      :rows="20"
      class="p-datatable-sm"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50]"
      currentPageReportTemplate="Mostrar {first} ao {last} de {totalRecords} faltas"
      responsiveLayout="scroll"
    >
      <template #empty> Não existem manuais. </template>
      <template #loading> A carregar. Por favor Aguarde... </template>
      <template #header>
        <div
          class="table-header"
          v-if="$store.state.auth.user.isEsa || can('manageManuals')"
        >
          <Button icon="pi pi-plus" @click="newCategory()" />
          <Button
            label="Manuais"
            class="p-ml-1"
            icon="pi pi-book"
            @click="$router.push(`/manuals`)"
          />
        </div>
      </template>
      <Column field="name" header="Manual" sortable filterMatchMode="contains">
        <template #body="slotProps">
          {{ slotProps.data.name }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.name.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column
        field="parentName"
        header="Categoria Superior"
        sortable
        filterMatchMode="contains"
      >
        <template #body="slotProps">
          {{ slotProps.data.parentName }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.parentName.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column
        field="comment"
        header="Comentário"
        sortable
        filterMatchMode="contains"
      >
        <template #body="slotProps">
          {{ slotProps.data.comment }}
        </template>
        <template #filter>
          <InputText
            type="text"
            v-model="filters.comment.value"
            class="p-column-filter"
          />
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <Button
            v-if="
              $store.state.auth.user.isEsa || can('manageManuals')
            "
            icon="pi pi-pencil"
            v-tooltip.left="'Editar'"
            class="p-button-sm p-button-rounded p-button-success"
            @click="editCategory(slotProps.index, slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="categoryModel.showForm"
      :style="{ width: $isMobile() ? '100vw' : '40vw' }"
      :header="
        (categoryModel.id == undefined ? 'Criar' : 'Actualizar') +
        ' Categoria de Manual'
      "
      :modal="true"
      :contentStyle="{ overflow: 'visible' }"
    >
      <form
        name="vacationsForm"
        class="p-col-12"
        @submit.prevent="saveCategory"
      >
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <InputText
                type="text"
                v-validate="'required'"
                v-model="categoryModel.name"
                v-bind:class="[
                  { 'p-invalid': errors.has('categoryName') },
                  'form-control',
                ]"
                :name="'categoryName'"
              />
              <label for="categoryName">Nome da Categoria</label>
            </span>
            <small
              v-if="errors.has('categoryName')"
              class="p-error"
              role="alert"
            >
              Nome é obrigatório
            </small>
          </div>

          <div class="p-field p-col-12 p-md-6">
            <span class="p-float-label">
              <dropdown
                v-model="categoryModel.parentId"
                :options="listParentsCategories"
                optionLabel="name"
                optionValue="id"
                :filter="true"
                :showClear="true"
                :name="'manualParentCategory'"
                v-bind:class="['form-control']"
              />
              <label for="manualParentCategory">Categoria Superior</label>
            </span>
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText
                type="text"
                v-model="categoryModel.comment"
                v-bind:class="['form-control']"
                :name="'categoryComment'"
              />
              <label for="categoryComment">Comentário</label>
            </span>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveCategory"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeCategoryForm"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import manualsService from "../services/manuals.service";
import { FilterMatchMode } from "primevue/api/";
export default {
  name: "ManualsCategories",
  data() {
    return {
      loading: true,
      listCategories: [],
      listParentsCategories: [],
      filters: {
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        parentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        comment: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      categoryModel: { showForm: false },
    };
  },
  async created() {
    this.listCategories = await this.getCategoriesList();
    await this.getParentCategories();
    this.loading = false;
  },
  methods: {
    getCategoriesList() {
      return manualsService.getCategoriesList().then((response) => response);
    },
    getParentCategories() {
      return manualsService
        .getCategoriesParentList()
        .then((response) => (this.listParentsCategories = response));
    },
    closeCategoryForm() {
      this.$validator.pause();
      this.$validator.reset();
      return (this.categoryModel = { showForm: false });
    },
    newCategory() {
      return (this.categoryModel.showForm = true);
    },
    editCategory(index, category) {
      return (this.categoryModel = {
        index: index,
        showForm: true,
        id: category.id,
        name: category.name,
        parentId: category.parentId,
        comment: category.comment,
      });
    },
    saveCategory() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          return;
        }
        this.$validator.pause();
        this.$validator.reset();

        if (this.categoryModel.id === undefined) {
          return this.saveNewCategory();
        }

        return this.updateCategory();
      });
    },
    saveNewCategory() {
      let bodyParams = {
        name: this.categoryModel.name,
      };
      if (
        this.categoryModel.parentId != undefined &&
        this.categoryModel.parentId > 0
      ) {
        bodyParams.parent_category = this.categoryModel.parentId;
      }

      if (this.categoryModel.comment !== undefined) {
        bodyParams.comment = this.categoryModel.comment;
      }

      return manualsService
        .addCategory(bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao inserir o manual",
              life: 3000,
            });
          }
          this.listCategories.push(response);
          this.$toast.add({
            severity: "success",
            summary: "Manual inserido",
            detail: "O Manual inserido com sucesso",
            life: 3000,
          });
          return this.closeCategoryForm();
        })
        .then(() => this.getParentCategories());
    },
    updateCategory() {
      let bodyParams = {
        name: this.categoryModel.name,
      };
      if (
        this.categoryModel.parentId != undefined &&
        this.categoryModel.parentId > 0
      ) {
        bodyParams.parent_category = this.categoryModel.parentId;
      }

      if (
        this.categoryModel.comment !== undefined &&
        this.categoryModel.comment != ""
      ) {
        bodyParams.comment = this.categoryModel.comment;
      }
      return manualsService
        .updateCategory(this.categoryModel.id, bodyParams)
        .then((response) => {
          if (!response) {
            return this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao actualizar a categoria manual",
              life: 3000,
            });
          }
          this.listCategories.splice(this.categoryModel.index, 1);
          this.listCategories[this.categoryModel.index] = response;
          this.$toast.add({
            severity: "success",
            summary: "Categoria atualizada",
            detail: "A Categoria do Manual foi atualizado com sucesso",
            life: 3000,
          });
          return this.closeCategoryForm();
        })
        .then(() => this.getParentCategories());
    },
  },
};
</script>
